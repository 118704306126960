.container {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
  width: 100%;

  @media (--medium) {
    height: 40px;
  }
}

.closeIcon {
  cursor: pointer;
  display: inline-block;
  height: 24px;
  position: absolute;
  right: 2.5%;
  width: 24px;
}
