.title {
    font-family: var(--font-termina);
    font-size: 36px;
    font-weight: 700;
    line-height: 125%;

    @media (--large) {
        font-size: 46px;
    }

    @media (--xlarge) {
        font-size: 64px;
    }
}

.subtitle {
    font-family: var(--font-termina);
    font-size: 28px;
    font-weight: 700;
    line-height: 130%;

    @media (--large) {
        font-size: 34px;
    }

    @media (--xlarge) {
        font-size: 44px;
    }
}

.lightTitle {
    font-family: var(--font-termina);
    font-size: 20px;
    font-weight: 300;
    line-height: 140%;

    @media (--large) {
        font-size: 23px;
    }
}

.sectionTitle {
    font-family: var(--font-brown);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 6px;
    line-height: 140%;

    @media (--large) {
        font-size: 23px;
    }
}

.articleTitle {
    font-family: var(--font-termina);
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;

    @media (--large) {
        font-size: 32px;
    }
}

.modalTitle {
    font-family: var(--font-termina);
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;

    @media (--large) {
        font-size: 23px;
    }
}

.newsTitle {
    font-family: var(--font-termina);
    font-size: 16px;
    font-weight: normal;
    line-height: 140%;

    @media (--large) {
        font-size: 24px;
    }
}

.productTitle {
    font-family: var(--font-termina);
    font-size: 23px;
    font-weight: 300;
    line-height: 140%;
}

.smallSubtitle{
    font-family: var(--font-termina);
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.landingPageTitle {
    font-family: var(--font-termina);
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;

    @media (--medium) {
        font-size: 24px;
        line-height: 36px;
    }

    @media (--xlarge) {
        font-size: 36px;
        line-height: 50px;
    }
}

.productCatalogTitle {
    font-family: var(--font-termina);
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;

    @media (--medium) {
        font-size: 30px;
        line-height: 32px;
    }
}

.description {
    font-family: var(--font-brown);
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    @media (--xlarge) {
        font-size: 18px;
        line-height: 26px;
    }
}

.descriptionBold {
    font-family: var(--font-brown);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    @media (--large) {
        font-size: 18px;
    }
}

.descriptionTermina {
    font-family: var(--font-termina);
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    @media (--large) {
        font-size: 18px;
        line-height: 32px;
    }
}

.lightDescription {
    font-family: var(--font-brown);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    @media (--large) {
        font-size: 18px;
        line-height: 32px;
    }
}

.smallDescriptionBold {
    font-family: var(--font-brown);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    @media (--large) {
        font-size: 14px;
    }
}

.smallDescription {
    font-family: var(--font-brown);
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    @media(--medium) {
        font-size: 14px;
    }
}

.smallTermina {
    font-family: var(--font-termina);
    font-size: 14px;
    line-height: 18px;
}

.heroDescription {
    font-family: var(--font-brown);
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;

    @media (--large) {
        font-size: 20px;
        line-height: 32px;
    }
}

.productCatalogItemTitle {
    font-family: var(--font-termina);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    @media (--medium) {
        font-size: 20px;
        line-height: 36px;
    }
}

.productCatalogName {
    font-family: var(--font-termina);
    font-size: 16px;
    font-weight: normal;

    @media (--medium) {
        font-size: 18px;
    }
}

/* Colors */

.black {
    color: black;
}

.white {
    color: white;
}

.gray {
    color: gray;
}

.gray-02 {
    color: var(--color-grey-02);
}

.primary {
    color: var(--color-primary);
}

.darkPeach {
    color: var(--color-dark-peach);
}

.green {
    color: var(--color-green);
}

.red {
    color: var(--color-red);
}

.lavender {
    color: var(--color-lavender);
}

.greenLime {
    color: var(--color-green-lime);
}

.greenYellow {
    color: var(--color-green-yellow);
}

.dodgerBlue {
    color: var(--color-dodger-blue);
}

.deepBlue {
    color: var(--color-deep-blue);
}

.hotPink {
    color: var(--color-hot-pink);
}

.purple {
    color: #DD80A8;
}

/* Font weight */

.font-100 {
    font-weight: 100 !important;
}

.font-200 {
    font-weight: 200 !important;
}

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-700 {
    font-weight: 700 !important;
}

.font-bold {
    font-weight: bold !important;
}

.striked {
    text-decoration: line-through;
}
