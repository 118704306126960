/* BROWN FONTS */
@font-face {
  font-display: swap;
  font-family: 'Brown';
  font-weight: 300;
  src: url('https://static.base.co.id/fonts/Brown/Brown-Light.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Brown';
  src: url('https://static.base.co.id/fonts/Brown/Brown-Regular.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Brown';
  font-weight: 700;
  src: url('https://static.base.co.id/fonts/Brown/Brown-Bold.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Brown';
  font-style: italic;
  font-weight: 300;
  src: url('https://static.base.co.id/fonts/Brown/Brown-Light-Italic.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Brown';
  font-style: italic;
  src: url('https://static.base.co.id/fonts/Brown/Brown-Regular-Italic.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Brown';
  font-style: italic;
  font-weight: 700;
  src: url('https://static.base.co.id/fonts/Brown/Brown-Bold-Italic.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

/* TERMINA FONTS */

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 100;
  src: url('https://static.base.co.id/fonts/Termina/Termina-Thin.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 200;
  src: url('https://static.base.co.id/fonts/Termina/Termina-ExtraLight.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 300;
  src: url('https://static.base.co.id/fonts/Termina/Termina-Light.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 400;
  src: url('https://static.base.co.id/fonts/Termina/Termina-Regular.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 500;
  src: url('https://static.base.co.id/fonts/Termina/Termina-Medium.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 600;
  src: url('https://static.base.co.id/fonts/Termina/Termina-Demi.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

@font-face {
  font-display: swap;
  font-family: 'Termina';
  font-weight: 700;
  src: url('https://static.base.co.id/fonts/Termina/Termina-Bold.woff') format('woff');
  unicode-range: U+00E8, U+0026, U+21, U+22, U+27-3B, U+3D, U+3F, U+41-5A, U+61-7A;
}

:root {
  /* nprogress color */
  --nprogress-color: #f58a7d;

  /* Gradients */
  --color-gradient-1: linear-gradient(
    201.01deg,
    #ff7461 20%,
    #f37951 30%,
    #f58a7d 40%,
    #f7932b 50%,
    #f7932b 60%,
    #f4bd23 70%,
    #f9d069 80%
  );
  --color-gradient-2: linear-gradient(122.42deg, #cc70d6 9.14%, #f06c64 108.94%);
  --color-gradient-3: linear-gradient(170.9deg, #f3e7e7 18.33%, #d490eb 122.14%);
  --color-gradient-4: linear-gradient(0deg, #f99e37 0%, #ff6f61 100%);
  --color-gradient-5: linear-gradient(
    300deg,
    #ff7461 15%,
    #f58a7d 25%,
    #f7932b 35%,
    #f4bd23 45%,
    #f9d069 55%,
    #f4bd23 65%,
    #f7932b 75%,
    #f58a7d 85%,
    #ff7461 95%
  );
  --color-gradient-6: linear-gradient(
    201.01deg,
    #aaceb5 20%,
    #f58a7d 30%,
    #ff7461 40% #9ca897 80%,
    #cebaaa 90%
  );
  --color-gradient-7: linear-gradient(170.9deg, #97c3a9 18.33%, #f1f1f1 122.14%);

  --color-gradient-8: linear-gradient(
    140deg,
    rgba(248, 101, 176, 1) 4%,
    rgba(248, 228, 219, 1) 20%,
    rgba(243, 184, 188, 1) 70%,
    rgba(233, 106, 150, 1) 87%
  );

  --color-gradient-9: linear-gradient(
    201.01deg,
    #007ddb 20%,
    #f0be36 30%,
    #ffede0 40%,
    #f06f63 50%,
    #c874e6 80%
  );

  --color-gradient-10: linear-gradient(201.01deg, #b873d6 0%, #f2d500 25%, #b873d6 100%);
  --color-gradient-11: linear-gradient(201.01deg, #f1d400 0%, #f1d400 50%, #b673d4 100%);

  --color-gradient-sidebar: linear-gradient(
    180deg,
    #ff6f61 0%,
    #f37951 41.15%,
    #f7932b 75.96%,
    #f4bd23 98.73%
  );
  --color-take-the-quiz: linear-gradient(209.74deg, #ffffff 15%, #fdd1b1 60%, #f4bd23 95%);

  /* Neutral Colors */
  --color-silver: #c4c4c4;
  --color-grey: #808080;
  --color-grey-01: #ebebeb;
  --color-grey-02: #e7e7e7;
  --color-grey-03: #cccccc;
  --color-grey-04: #666666;
  --color-grey-alpha-01: rgba(188, 188, 188, 0.1);
  --color-grey-alpha-02: rgba(128, 128, 128, 0.2);
  --color-white-alpha-05: rgba(255, 255, 255, 0.5);
  --color-white-alpha-09: rgba(255, 255, 255, 0.9);

  /* Black Alternatives */
  --color-night-rider: #333;

  /* Primary Color */
  --color-primary: #ff6f61;
  --color-primary-light: #f58a7d;
  --color-primary-dark: #c45449;

  /* Alternative Colors */
  --color-red: #eb5757;
  --color-outrageous-orange: #f15a24;
  --color-light-orange: #f37951;
  --color-yellow: #f4bd23;
  --color-green: #009874;
  --color-lavender: #c874e6;
  --color-deep-blue: #0071bc;
  --color-dodger-blue: #006bff;
  --color-blue-navy: #254e82;
  --color-deep-orange: #ea612b;
  --color-dark-peach: #f8a16d;
  --color-light-yellow: #f9d069;
  --color-grass: #76c180;
  --color-hot-pink: #fc65b4;
  --color-pink: #e96a96;
  --color-picton-blue: #050607;
  --color-orange: #f37522;
  --color-peach: #fdd1b1;
  --color-peach-light: #ffeddb;
  --color-egg-white: #ffedbf;
  --color-light-green: #78c9b4;
  --color-green-lime: #bcfda4;
  --color-green-yellow: #ccff33;
  --color-light-yellow-green: #dde5b5;
  --color-aqua: #00ffff;
  --color-sea-buckthorn: #f7932b;
  --color-beauty-bush: #e9bcb6;
  --color-cinderella: #fbd7ca;
  --color-peach-cream: #ffede0;
  --color-sage-green: #c2dea1;
  --color-yellow-neon: #fcff00;
  --color-mercury: #e5e5e5;
  --color-seashell-peach: #fff7ef;
  --color-seashell: #faf8f6;
  --color-zambezi: #5a5a5a;
  --color-mango: #f99e37;
  --color-light-mango: #fffbf6;
  --color-subtle-grey: #545454;

  /* Fonts */
  --font-brown: Brown, sans-serif;
  --font-termina: Termina, serif;
}

/* CUSTOM MEDIA */
@custom-media --xxsmall (max-width: 320px);
@custom-media --xsmall (max-width: 480px);
@custom-media --small (min-width: 480px);
@custom-media --ltemedium (max-width: 768px);
@custom-media --medium (min-width: 768px);
@custom-media --large (min-width: 1024px);
@custom-media --xlarge (min-width: 1200px);
@custom-media --xxlarge (min-width: 1600px);

/* IPAD ORIENTATION */
@custom-media --ipad-portrait screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait);
@custom-media --ipad-landscape screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape);
@custom-media --ipad-pro-portrait screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait);
@custom-media --ipad-pro-landscape screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape);
