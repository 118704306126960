:global(*) {
  transition: all 0.2s ease-out;
}

::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

body {
  text-rendering: optimizeLegibility;
}

/* Google Anti Flicker Snippet */
.async-hide {
  opacity: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

li {
  padding: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

input {
  border: none;
}

button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}

#__next {
  position: relative;
}

#snack-bar {
  display: none;
  height: 60px;
  max-height: 60px;
  max-width: 100vw;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;

  @media (--medium) {
    height: 40px;
    max-height: 40px;
  }
}

#modal-view,
#drawer-view {
  background: rgba(81, 81, 81, 0.6);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 300;
}

#toast-view {
  align-items: flex-end;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 400;
}

@keyframes rotation-end {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
