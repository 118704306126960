.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  width: 100vw;
}

.clickable {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.5;
  }
}
