.header {
  height: 60px;
  max-width: 100vw;
  position: fixed;
  top: 60px;
  z-index: 100;

  @media (--medium) {
    top: 40px;
  }

  &.moveUp {
    top: 0;
  }
}

/* 
    SNACKBAR
 */

.snackbar {
  background-color: #EC7877;
}

.snackbarText {
  color: black;
  display: inline-block;
  max-width: 80vw;
  text-align: center;


  @media (--medium) {
    margin: 10px 0;
    max-width: 90vw;
  }
}

.snackbarLink {
  color: black;
  cursor: pointer;
  display: inline-block;
  font-weight: bolder !important;
  text-decoration: underline;
}

.voucherCode {
  text-decoration: underline;
}
